<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1 gram-logo">
          Gram
        </h2>
      </b-link>
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Забыли пароль? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Введите адрес электронной почты, и мы вышлем вам инструкции по сбросу пароля
          </b-card-text>
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
            <template v-if="nextStep">
              <!-- Логин -->
              <b-form-group
                label="Логин пользователя"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="login"
                  rules="required"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="login"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="логин"
                  />
                  <small class="text-danger" v-if="errors[0]">{{ 'Логин обязателен для заполнения' }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                @click.prevent="getCode()"
                type="submit"
                variant="primary"
                block
              >
                Получить код
              </b-button>
            </template>
          </b-form>

          <b-form>
           <template v-if="!nextStep">
             <!-- Код смс -->
             <b-form-group
                label="Подтверждение смс кода"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Код"
                  rules="required"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="smsCode"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="xxx-xxx"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                @click.prevent="getPassword()"
                type="submit"
                variant="primary"
                block
              >
                Восстановить пароль
              </b-button>
           </template>
          </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Назад
            </b-link>
          </p>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      login: undefined,
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      required,
      email,
      smsCode: undefined,
      nextStep: true,
      password_changes_id: undefined
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    getCode(){
      this.$http.post(`auth/password-changes?login=${this.login}`)
          .then(res=>{
            this.password_changes_id = res.data.password_changes_id
            this.nextStep=false
          }).catch(err=>{
            })
            
            
    },
    getPassword(){
      this.$http.post(`auth/password-changes/2?password_changes_id=${this.password_changes_id}&sms_code=${this.smsCode}`)
          .then(res=>{
            this.nextStep=true
            this.$router.push({ name: 'auth-login' })
          }).catch(err=>{
            })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'This is for UI purpose only.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
.gram-logo{
  color: #042AEC !important;
}
</style>
